import axios from 'axios'
//import store from '@/store'
//import i18n from '@/libs/i18n'


axios.interceptors.response.use(
  (response) => {
      // Return a successful response back to the calling service
      return response
  },
  (error) => {
    /*if (error.response.status === 404) {
      store.commit('notfound', true);
    } else if (error.response.status === 401 || error.response.status === 403) {
      const pathname = location.pathname;
      if (!pathname.includes('login')) {

        const regexAdmin = /admin\/(.)+/g;
        const isAdmin = regexAdmin.exec(pathname);

        if (!isAdmin) {
          store.commit('notfound', true);
        } else {
          store.commit('notifications', {title: i18n.messages.errorData, color: 'danger'});
          store.commit('showNotifications', true);
        }
      }
    }*/

    return Promise.reject(error);
  }
)

