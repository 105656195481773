import ordersApi from "../../api/orders-api";
import router from "../../router";
import store from "../../store";

export default {
  namespaced: true,
  state: {
    listOrders: [],
    order: {},
    successAddOrder: false,
    errorAddOrder: false,
    totalListOrders: 0,
    productsUnavailable: [],
    showModalStockAvailable: false,
    fecha: "",
    comment: "",
  },
  mutations: {
    setListOrders(state, payload) {
      state.listOrders = payload.data;
    },
    setOrder(state, payload) {
      state.order = payload;
    },
    setSuccessAdd(state, payload) {
      state.successAddOrder = payload;
    },
    setErrorAdd(state, payload) {
      state.errorAddOrder = payload;
    },
    setTotalListOrders(state, payload) {
      state.totalListOrders = payload.data.length;
    },
    setShowModalStockAvailable(state, payload) {
      state.showModalStockAvailable = payload;
      console.log(state.showModalStockAvailable);
    },
    setProductsUnavailable(state, payload) {
      state.productsUnavailable = payload;
    },
    setFecha(state, payload) {
      state.fecha = payload;
    },
    setComment(state, payload) {
      state.comment = payload;
    },
  },
  actions: {
    async searchOrders(
      context,
      {
        limit = 10,
        offset = 0,
        sort = "",
        idClient = 0,
        fecha_ini = "",
        fecha_fin = "",
        search,
      }
    ) {
      const res = await ordersApi.getListOrdersByClient(
        limit,
        offset,
        sort,
        idClient,
        fecha_ini,
        fecha_fin
      );

      console.log(res.data);
      if (search != "") {
        search = search.toUpperCase();
        res.data = res.data.filter(function(el) {
          return (
            el.Pedido.toUpperCase().includes(search) ||
            el.Referencia.toUpperCase().includes(search)
          );
        });
      }

      //  console.log(res.data);
      context.commit("setListOrders", res);
      context.commit("setTotalListOrders", res);
    },
    async getOrder(context, order) {
      console.log(order);
      const res = await ordersApi.getOrder(order.Pedido, 0);

      order.lineas = res.data;
      console.log("ORDER: ", order);
      context.commit("setOrder", order);
    },
    async createOrder(context, order) {
      //CREA ORDER
      await ordersApi.createCabecera(order).then(
        // eslint-disable-next-line no-unused-vars
        async (response) => {
          console.log(response);
          if (response.data.length > 0) {
            if (
              response.data[0].Order != null &&
              response.data[0].Order != undefined
            ) {
              if (response.data[0].Order >= 0) {
                //Correcto
                var lineasCorrectas = 0;
                var totalLineas = 0;
                await order.lineas.forEach((linea) => {
                  (async () => {
                    try {
                      linea.IDPEDIDO = response.data[0].Order;
                      console.log(linea);
                      await ordersApi.createLinea(linea).then(
                        // eslint-disable-next-line no-unused-vars
                        (response) => {
                          console.log(response);

                          totalLineas++;

                          if (response.data.length > 0) {
                            if (
                              response.data[0].Line != null &&
                              response.data[0].Line != undefined
                            ) {
                              if (response.data[0].Line >= 0) {
                                //Correcto
                                lineasCorrectas++;
                              } else {
                                console.log("ERROR1 Linea: ", error);
                              }
                            } else {
                              console.log("ERROR2 Linea: ", error);
                            }
                          } else {
                            console.log("ERROR3 Linea: ", error);
                          }
                        },
                        // eslint-disable-next-line no-unused-vars
                        (error) => {
                          console.log("ERROR4 Linea : ", error);
                          //TODO notificación error pedido
                        }
                      );
                    } catch (e) {
                      console.log(e.stack);
                    } finally {
                      console.log(`Linea creada`);
                      console.log(lineasCorrectas);
                      console.log(order.lineas.length);
                      if (totalLineas == order.lineas.length) {
                        if (lineasCorrectas == order.lineas.length) {
                          var obj = {
                            idPedido: response.data[0].Order,
                            idCarrito: order.idCarrito,
                          };
                          await ordersApi.confirmOrder(obj).then(
                            // eslint-disable-next-line no-unused-vars
                            (response) => {
                              console.log(response);
                              if (response.data.length > 0) {
                                if (
                                  response.data[0].Lines != null &&
                                  response.data[0].Lines != undefined
                                ) {
                                  if (response.data[0].Lines >= 0) {
                                    //Correcto
                                    if (response.data[0].Lines == totalLineas) {
                                      console.log("TOT CORRECTE ");
                                      localStorage.removeItem("cart");
                                      store.dispatch("carts/getCartOrCreate", {
                                        username: "",
                                        idClient: "",
                                      });
                                      console.log(context);
                                      context.commit("setSuccessAdd", true);
                                    } else {
                                      console.log("ERROR0  Confirmar pedido: ");
                                      context.commit("setErrorAdd", true);
                                    }
                                  } else {
                                    console.log("ERROR1  Confirmar pedido: ");
                                    context.commit("setErrorAdd", true);
                                  }
                                } else {
                                  console.log("ERROR2  Confirmar pedido: ");
                                  context.commit("setErrorAdd", true);
                                }
                              } else {
                                console.log("ERROR3  Confirmar pedido: ");
                                context.commit("setErrorAdd", true);
                              }
                            },
                            // eslint-disable-next-line no-unused-vars
                            (error) => {
                              console.log("ERROR4 Confirmar pedido : ", error);
                              context.commit("setErrorAdd", true);
                              //TODO notificación error pedido
                            }
                          );
                        } else {
                          console.log("ERROR2  Confirmar pedido: ");
                          context.commit("setErrorAdd", true);
                        }
                      }
                    }
                  })();
                });
              } else {
                console.log("ERROR Cabecera: ", error);
              }
            } else {
              console.log("ERROR Cabecera: ", error);
            }
          } else {
            console.log("ERROR Cabecera: ", error);
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log("ERROR CREAR CABECERA: ", error);
          //TODO notificación error pedido
        }
      );
    },
  },
  getters: {
    getListOrders: (state) => state.listOrders,
    getOrder: (state) => state.order,
    getTotalListOrders: (state) => state.totalListOrders,
    getShowModalStockAvailable: (state) => state.showModalStockAvailable,
    getProductsUnavailable: (state) => state.productsUnavailable,
    getSuccessAdd: (state) => state.successAddOrder,
    getErrorAdd: (state) => state.errorAddOrder,
  },
};
