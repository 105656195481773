import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import customers from "./customers";
import products from "./products";
import users from "./users";
import orders from "./orders";
import reports from "./reports";
import carts from "./carts";
import families from "./families";
import sizes from "./sizes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    customers,
    products,
    users,
    orders,
    reports,
    carts,
    families,
    sizes,
  },
  strict: process.env.DEV,
});
