import i18n from "@/libs/i18n";

export const config = {
  locales: [
    {
      locale: "es",
      img: require("@/assets/images/flags/es.svg"),
      name: "ES",
    },
    {
      locale: "en",
      img: require("@/assets/images/flags/en.svg"),
      name: "EN",
    },
  ],
  estadosPedidos: {
    "Facturado/Cerrado": "Facturado/Cerrado",
    NecesitaAprobacion: "Necesita Aprobacion",
    Creado: "Creado",
    Planificado: "Planificado",
    Lanzado: "Lanzado",
    Reservado: "Reservado",
    Recogido: "Recogido",
    "Entrega Parcial": "Entrega Parcial",
    Entregado: "Entregado",
    Cancelado: "Cancelado",
    Bloqueado: "Bloqueado",
  },
  colorPedidos: {
    "Facturado/Cerrado": "success",
    Reservado: "danger",
    Recogido: "success",
    "Entrega Parcial": "danger",
    Entregado: "success",
    Cancelado: "danger",
    Bloqueado: "danger",
    Planificado: "success",
  },
  webApiBase: process.env.VUE_APP_ROOT_API,
};
