import sizesApi from "../../api/sizes-api";

export default {
  namespaced: true,
  state: {
    listSizes: [],
  },
  mutations: {
    setListSizes(state, payload) {
      state.listSizes = payload.data;
    },
  },
  actions: {
    async searchSizes(context, family) {
      const res = await sizesApi.getSizes(family);

      var array = [
        {
          text: "Todos",
          value: null,
        },
      ];

      res.data.forEach((element) => {
        array.push(element.Formato);
      });
      res.data = array;
      context.commit("setListSizes", res);
    },
  },
  getters: {
    getListSizes: (state) => state.listSizes,
  },
};
