import familiesApi from "../../api/families-api";

export default {
  namespaced: true,
  state: {
    listFamilies: [],
  },
  mutations: {
    setListFamilies(state, payload) {
      state.listFamilies = payload.data;
    },
  },
  actions: {
    async searchFamilies(context, format) {
      const res = await familiesApi.getFamilies(format);

      var array = [
        {
          text: "Todos",
          value: null,
        },
      ];
      res.data.forEach((element) => {
        array.push(element.Familia);
      });
      res.data = array;
      context.commit("setListFamilies", res);
    },
  },
  getters: {
    getListFamilies: (state) => state.listFamilies,
  },
};
