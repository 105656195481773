import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/users";
const RESOURCE_NAME_AUTH = "/auth";

export default {
  getListUsersPending(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/pending`}`, {
      params,
    });
  },
  exportUsersPending(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/export/pending`}`,
      {
        params,
      }
    );
  },
  setLanguage(language, userID) {
    console.log("idioma: ", language);
    console.log("userID: ", userID);
    const params = {
      userID: userID,
      language: language,
    };

    return axios.post(`${config.webApiBase + RESOURCE_NAME}/language`, {
      userID,
      language,
    });
  },
  getListUsersRegistered(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/registered`}`, {
      params,
    });
  },
  exportUsersRegistered(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/export/registered`}`,
      {
        params,
      }
    );
  },
  getListUsersActive(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/active`}`, {
      params,
    });
  },
  exportUsersActive(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/export/active`}`,
      {
        params,
      }
    );
  },
  getListUsersInactive(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/inactive`}`, {
      params,
    });
  },
  getListUsersBlocked(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/blocked`}`, {
      params,
    });
  },
  exportUsersBlocked(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/export/blocked`}`,
      {
        params,
      }
    );
  },
  getListUsersClient(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/clients`}`, {
      params,
    });
  },
  getListUsersCommercial(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/commercials`}`, {
      params,
    });
  },
  exportUsersCommercial(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/export/commercials`}`,
      {
        params,
      }
    );
  },
  getListUsersCommercialERP(search, limit, offset, sort, currentPage) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }

    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/commercialserp`}`,
      {
        params,
      }
    );
  },
  getProduct(idProduct, comercial = 0) {
    const params = {
      comercial,
    };

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/${idProduct}`}`, {
      params,
    });
  },
  /*   getPrice(params) {
    console.log("PARAMS2: ", params);
    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/price/${params.idProduct}?codcli=${
        params.codcli
      }&inputmeas=pcs&quantity=${params.quantity}&almacen=D111`
    );
  }, */

  linkClient(CustomerID, UserID) {
    console.log("CustomerID: ", CustomerID);
    console.log("UserID: ", UserID);
    /*     return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/price/${params.idProduct}?codcli=${
        params.codcli
      }&inputmeas=pcs&quantity=${params.quantity}&almacen=D111`
    ); */
    return axios.post(
      `${config.webApiBase +
        RESOURCE_NAME_AUTH}/${UserID}/customer/${CustomerID}`
    );
  },

  unlinkClient(UserID) {
    console.log("UserID: ", UserID);
    /*     return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/price/${params.idProduct}?codcli=${
        params.codcli
      }&inputmeas=pcs&quantity=${params.quantity}&almacen=D111`
    ); */
    return axios.post(
      `${config.webApiBase + RESOURCE_NAME_AUTH}/${UserID}/unlink`
    );
  },
};
