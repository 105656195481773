import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/carritos";
const RESOURCE_NAME_SINGULAR = "/carrito";
const RESOURCE_CUSTOMERS = "/customers";

export default {
  getCartByClient(username = "", idClient = "") {
    console.log("username2:: ", username);
    console.log("idClient2:: ", idClient);
    const user = store.getters["auth/getUser"];

    if (!idClient) {
      const client = store.getters["customers/getCurrentCustomer"];
      console.log("client: ", client);
      if (client) {
        idClient = client.Cliente;
      }
    }
    if (!username) {
      console.log("IDUSER: ", user);
      username = user.usuario_id;
    }

    // const user = store.getters["auth/getUser"];
    //  console.log("IDUSER: ", user);

    if (user.rol_id === 2) {
      //Obtenemos el carrito de ese usuario como cliente y "admin" como comercial
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comprador/${
          user.usuario_id
        }/vendedor/admin`
      );
    }

    if (idClient == "" || username == "") {
      return { data: [] };
    }

    return axios.get(
      `${config.webApiBase +
        RESOURCE_NAME}/comprador/${idClient}/vendedor/${username}`
    );
  },
  getCartByVendedor(username = "") {
    console.log("username2:: ", username);
    const user = store.getters["auth/getUser"];

    if (!username) {
      console.log("IDUSER: ", user);
      username = user.usuario_id;
    }

    // const user = store.getters["auth/getUser"];
    //  console.log("IDUSER: ", user);

    if (user.rol_id === 2) {
      //Obtenemos el carrito de ese usuario como cliente y "admin" como comercial
      return axios.get(`${config.webApiBase + RESOURCE_NAME}/vendedor/admin`);
    }

    /*   if (idClient == "" || username == "") {
      return { data: [] };
    } */

    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/vendedor/${username}`
    );
  },
  getBilling(username = "") {
    //  console.log("username2:: ", username);
    // const client = store.getters["customers/getCurrentCustomer"];
    const client = JSON.parse(localStorage.getItem("currentCustomer"));

    if (!username) {
      console.log("client: ", client);
      if (client) {
        username = client.Cliente;
      }
    }
    if (username == "") {
      return { data: [] };
    }
    return axios.get(
      `${config.webApiBase + RESOURCE_CUSTOMERS}/billing/${username}`
    );
  },
  getDelivery(username = "") {
    //  console.log("username2:: ", username);
    // const client = store.getters["customers/getCurrentCustomer"];
    const client = JSON.parse(localStorage.getItem("currentCustomer"));

    if (!username) {
      console.log("client: ", client);
      if (client) {
        username = client.Cliente;
      }
    }
    if (username == "") {
      return { data: [] };
    }
    return axios.get(
      `${config.webApiBase + RESOURCE_CUSTOMERS}/delivery/${username}`
    );
  },
  getCartLines(idCarrito = "") {
    console.log("IDCARRITO:: ", idCarrito);
    return axios.get(
      `${config.webApiBase + RESOURCE_NAME_SINGULAR}/${idCarrito}`
    );
  },
  createEmptyCart(username = "", idClient = "") {
    console.log("username2:: ", username);
    console.log("idClient2:: ", idClient);
    const user = store.getters["auth/getUser"];

    if (!idClient) {
      const client = store.getters["customers/getCurrentCustomer"];
      console.log("client: ", client);
      if (client) {
        idClient = client.Cliente;
      }
    }
    if (!username) {
      console.log("IDUSER: ", user);
      username = user.usuario_id;
    }

    var params = {
      comprador: idClient.toString(),
      vendedor: username.toString(),
    };

    if (user.rol_id === 2) {
      //Creamos el carrito de ese usuario como cliente y "admin" como comercial
      params = {
        comprador: user.usuario_id.toString(),
        vendedor: "admin",
      };
    }

    return axios.post(`${config.webApiBase + RESOURCE_NAME_SINGULAR}`, params);
  },
  addProduct(
    codart,
    cantidad,
    unitmeas,
    picking,
    estado,
    cajasTotales,
    cajasPalet,
    metrosCaja,
    peso,
    amount,
    nombre,
    tasa
  ) {
    const currentCart = store.getters["carts/getIdCurrentCart"];
    console.log(currentCart);

    const params = {
      codart,
      cantidad,
      unitmeas,
      picking,
      estado,
      cajasTotales: parseFloat(cajasTotales),
      cajasPalet: parseFloat(cajasPalet),
      metrosCaja,
      peso,
      amount: parseFloat(amount),
      nombre,
      tasa: parseFloat(tasa),
    };

    console.log(params);

    return axios.put(
      `${config.webApiBase + RESOURCE_NAME_SINGULAR}/${currentCart}/add`,
      params
    );
  },
  removeLine(idLinea) {
    return axios.delete(
      `${config.webApiBase + RESOURCE_NAME_SINGULAR}/linea/${idLinea}`
    );
  },
  updateLine(
    idCarrito,
    idLinea,
    refean,
    cantped,
    umedida,
    picking,
    cajasTotales,
    cajasPalet,
    metrosCaja,
    peso
  ) {
    const params = {
      refean,
      cantped,
      umedida,
      picking,
      cajasTotales,
      cajasPalet,
      metrosCaja,
      peso,
    };
    return axios.post(
      `${config.webApiBase +
        RESOURCE_NAME_SINGULAR}/${idCarrito}/edit/${idLinea}`,
      params
    );
  },
};
