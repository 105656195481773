import axios from "axios";

axios.interceptors.request.use(
  function(config) {
    const user = JSON.parse(localStorage.getItem("user"));

    console.log(config.url);

    if (
      user &&
      user.token &&
      config.url.startsWith("https://duneceramica.pinchaaqui.net")
    ) {
      // config.headers.Authorization = `Basic ZGVtbzphY2Nlc29DbGllbnRlc1BB`;
    } else if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
