import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/familia";

export default {
  getFamilies(format) {
    console.log(format);
    if (format != null) {
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}?formato=` + format,
        {}
      );
    }
    return axios.get(`${config.webApiBase + RESOURCE_NAME}`, {});
  },
};
