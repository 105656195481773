import axios from "axios";
import { config } from "../shared/app.config";
import store from "../store";

const RESOURCE_NAME = "/customers";

export default {
  getListCustomers(search) {
    console.log(search);
    const params = {};
    if (search) {
      params["codcom"] = search;
    }

    const username = store.getters["auth/getCurrentUsername"];
    const user = store.getters["auth/getUser"];

    console.log(JSON.stringify(user));

    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/comercial/${user.id_comercial}`,
      { params }
    );
  },
  getListCustomersAdmin(search) {
    const params = {};
    if (search) {
      params["name"] = search;
    }

    const username = store.getters["auth/getCurrentUsername"];

    /*  return axios.get(`${config.webApiBase + RESOURCE_NAME}/all/${username}`, {
      params,
    }); */

    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/comercial/${username}`,
      { params }
    );
  },
  getListCustomersForCommercial(username) {
    /*  return axios.get(`${config.webApiBase + RESOURCE_NAME}/all/${username}`, {
      params,
    }); */

    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/comercial/${username}`
    );
  },
  getCustomerLogged(search) {
    const params = {};
    if (search) {
      params["codcli"] = search;
    }

    return axios.get(`${config.webApiBase + RESOURCE_NAME}`, { params });
  },
};
