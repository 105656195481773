import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
function loadLocaleMessages(getLocale) {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  let messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (getLocale === locale) {
        messages = locales(key)
      }
    }
  })

  return messages
}
function getCurrentLanguage () {
  const current = localStorage.getItem('currentLanguage')
  if (current) {
    return current;
  } else {
    return 'es';
  }
}
export default new VueI18n({
  locale: getCurrentLanguage(),
  fallbackLocale: getCurrentLanguage(),
  messages: loadLocaleMessages(getCurrentLanguage()),
})
