import router from "../../router";
import authApi from "../../api/auth-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: { showModalDisclaimer: false, putNewPassword: false },
  data() {
    return { objLogin: null };
  },
  mutations: {
    setPutNewPassword(state, payload) {
      state.putNewPassword = payload;
    },
    recoverUser(state) {
      const recoveredUser = JSON.parse(localStorage.getItem("user"));
      if (!recoveredUser) {
        state.status = null;
        state.user = null;
      } else {
        state.status = { loggedIn: true };
        state.user = recoveredUser;
      }
    },
    refreshTokenSuccess(state, responseData) {
      state.user.token = responseData.token;
      // eslint-disable-next-line no-mixed-operators
      state.user.expires_in = new Date(
        new Date().getTime() + responseData.expires_in * 60 * 1000
      ).getTime();
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    loginSuccess(state, responseData) {
      console.log("RESPONSEDATA:: ", responseData[0]);
      state.status = { loggedIn: true };
      state.user = {
        usuario: responseData[0].NOMBRE,
        usuario_id: responseData[0].USUARIO_ID,
        nombre: responseData[0].NOMBRE,
        rol_id: responseData[0].ROL_ID,
        rol_nombre: responseData[0].ROL_NOMBRE,
        id_cliente: responseData[0].CODIGO_CLIENTE,
        id_comercial: responseData[0].CODIGO_COMERCIAL,
        language: responseData[0].LANGUAGE,
        email: responseData[0].USUARIO,
        token: responseData[0].token,
        // eslint-disable-next-line no-mixed-operators
        expires_in: new Date(
          new Date().getTime() + responseData[0].expires_in * 60 * 1000
        ).getTime(),
      };

      state.loginFailed = false;

      //ponemos la web al idioma del usuario
      localStorage.setItem("currentLanguage", responseData[0].LANGUAGE);
      //  location.reload();

      //copiamos la información del usuario al localStorage
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("cart");
      localStorage.removeItem("currentCustomer");
      localStorage.removeItem("searchProd");
      localStorage.removeItem("familyProd");
      localStorage.removeItem("sizeProd");
      localStorage.removeItem("currentPageProd");
      localStorage.removeItem("sortByProd");
      localStorage.removeItem("sortDescProd");

      localStorage.removeItem("PerPageOrder");
      localStorage.removeItem("fechaIniOrder");
      localStorage.removeItem("fechaFinOrder");
      localStorage.removeItem("currentPageOrder");
      localStorage.removeItem("sortByOrder");
      localStorage.removeItem("sortDescOrder");
      state.status = {};
      state.user = null;
      router.push({ name: "login" });
    },
    setShowModalDisclaimer(state, payload) {
      state.showModalDisclaimer = payload;
      console.log(state.showModalDisclaimer);
    },
  },
  actions: {
    checkToken({ commit, dispatch }) {
      const user = JSON.parse(localStorage.getItem("user"));
      const current = new Date().getTime();
      if (user) {
        if (current >= user.expires_in) {
          commit("notifications", {
            title: "Sesión expirada, vuelva a iniciar sesión.",
            color: "danger",
          });
          router.push({ name: "login" });
        } else if (user.expires_in - current < 1800000) {
          dispatch("refreshToken");
        }
      } else {
        router.push({ name: "login" });
      }
    },
    refreshToken({ commit }) {
      authApi.refreshToken().then((response) => {
        commit("refreshTokenSuccess", response.data);
      });
    },
    acceptedConditions({ commit }) {
      console.log("USUARIO: ", this.objLogin[0]);
      commit("loginSuccess", this.objLogin);

      if (this.objLogin[0].ROL_ID == 0) {
        location.href = "/admin/userspendent";
      } else {
        location.href = "/admin/products";
      }
    },
    login(
      { commit, dispatch },
      { username, password, setShowModalDisclaimer, toast }
    ) {
      authApi.login(username, password).then(
        (response) => {
          console.log(response);
          var obj = {
            usuario: response.data[0].NOMBRE,
            usuario_id: response.data[0].USUARIO_ID,
            nombre: response.data[0].NOMBRE,
            rol_id: response.data[0].ROL_ID,
            rol_nombre: response.data[0].ROL_NOMBRE,
            id_cliente: response.data[0].CODIGO_CLIENTE,
            id_comercial: response.data[0].CODIGO_COMERCIAL,
            language: response.data[0].LANGUAGE,
            token: response.data[0].token,
          };

          this.objLogin = response.data;

          dispatch("acceptedConditions");

          /*   commit("loginSuccess", response.data);
          location.href = "/admin"; */
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            if (error.response.data.Error == "User not active") {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Usuario no verificado por Dune Cerámica SL",
                  icon: "EditIcon",
                  variant: "light",
                },
              });
            }
            if (error.response.data.Error == "Password not updated") {
              /* toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Usuario validado. Comprueba tu email para cambiar tu contraseña",
                  icon: "EditIcon",
                  variant: "light",
                },
              }); */

              commit("setPutNewPassword", true);
            } else if (error.response.data.Error == "User deleted") {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Usuario eliminado",
                  icon: "EditIcon",
                  variant: "light",
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Usuario y/o contraseña incorrectos",
                  icon: "EditIcon",
                  variant: "light",
                },
              });
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },

    signin(
      { commit, dispatch },
      {
        name,
        password,
        language,
        email,
        phone,
        codcli,
        empresa,
        cif,
        cargo,
        country,
        setShowModalDisclaimer,
        toast,
      }
    ) {
      console.log({
        name,
        password,
        language,
        email,
        phone,
        codcli,
        empresa,
        cif,
        cargo,
        country,
      });
      authApi
        .signin(
          name,
          password,
          language,
          email,
          phone,
          codcli,
          empresa,
          cif,
          cargo,
          country
        )
        .then(
          (response) => {
            console.log(response);

            if (response.data.message === "The E-mail already in use") {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Ya existe un usuario con ese correo electrónico",
                  icon: "EditIcon",
                  variant: "light",
                  timeout: 7000,
                },
              });
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Usuario creado correctamente. Revise su correo electrónico",
                  icon: "EditIcon",
                  variant: "light",
                  timeout: 7000,
                },
              });

              setTimeout(() => {
                location.href = "";
              }, 3000);
            }

            // commit("loginSuccess", response.data);
          },
          (error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error al crear el usuario: " + error.message,
                  icon: "EditIcon",
                  variant: "light",
                  timeout: 7000,
                },
              });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the
              // browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          }
        );
    },
    forgot({ commit, dispatch }, { email, setShowModalDisclaimer, toast }) {
      console.log(email);
      authApi.forgot(email).then(
        (response) => {
          console.log(response);

          /*   location.href = "";

          toast({
            component: ToastificationContent,
            props: {
              title:
                "Usuario creado correctamente. Revise su correo electrónico",
              icon: "EditIcon",
              variant: "light",
            },
          }); */

          // commit("loginSuccess", response.data);
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error: El email introducido no es válido",
                icon: "EditIcon",
                variant: "light",
              },
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },
    newPassword(
      { commit, dispatch },
      { username, oldPassword, newPassword, setShowModalDisclaimer, toast }
    ) {
      console.log(username);
      console.log(oldPassword);
      console.log(newPassword);
      authApi.newPassword(username, oldPassword, newPassword).then(
        (response) => {
          console.log(response);
          toast({
            component: ToastificationContent,
            props: {
              title: "Contraseña actualizada correctamente",
              icon: "EditIcon",
              variant: "light",
            },
          });
        },
        (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error al actualizar la contraseña",
                icon: "EditIcon",
                variant: "light",
              },
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        }
      );
    },
    logout({ commit }) {
      commit("logout");
      router.push({ name: "login" });
    },
    removeAccount({ commit }, {toast}) {
      authApi.delete().then(
          (response) => {
            commit("logout");
            router.push({ name: "login" });
          },
          (error) => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error al eliminar la cuenta",
                icon: "EditIcon",
                variant: "light",
              },
            });
          }
      );
    },
  },
  getters: {
    getCurrentUsername: (state) => state.user.usuario,
    getUser: (state) => state.user,
    getPutNewPassword: (state) => state.putNewPassword,
    getShowModalDisclaimer: (state) => state.showModalDisclaimer,
  },
};
