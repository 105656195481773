import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/auth";
export default {
  login(username, password) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/login`, {
      username,
      password,
    });
  },
  signin(
    name,
    password,
    language,
    email,
    phone,
    codcli,
    empresa,
    cif,
    cargo,
    country
  ) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/signup`, {
      name,
      password,
      language,
      email,
      phone,
      codcli,
      empresa,
      cif,
      cargo,
      country,
    });
    /*   return axios.post(`https://dae0-81-202-139-110.ngrok.io/api/auth/signup`, {
      name,
      password,
      email,
    }); */
  },
  forgot(email) {
    /*  return axios.post(`${config.webApiBase + RESOURCE_NAME}/signup`, {
      email,
    }) */
    console.log(email);
    return axios.post(
      `${config.webApiBase + RESOURCE_NAME}/reset-password-email`,
      {
        email,
      }
    );
  },
  newPassword(username, oldPassword, newPassword) {
    console.log(username);
    console.log(oldPassword);
    console.log(newPassword);
    return axios.post(
      `${config.webApiBase + RESOURCE_NAME}/create-new-password`,
      {
        name: username,
        oldpassword: oldPassword,
        newpassword: newPassword,
      }
    );
  },
  refreshToken() {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/refreshToken`);
  },
  recoverPassword(email) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/email`, {
      email,
    });
  },
  resetPassword(email, password, passwordConfirmation, token) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/password/reset`, {
      email,
      password,
      passwordConfirmation,
      token,
    });
  },
  delete() {
    return axios.put(`${config.webApiBase + RESOURCE_NAME}/deleted-user`);
  },
};
