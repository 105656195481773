import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME_DETAILED = "/productsdetailed";
const RESOURCE_NAME = "/products";

export default {
  getListProducts(search, limit, offset, sort, family, size, currentPage) {
    const params = {
      search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }
    if (family) {
      params.family = family;
    }
    if (size) {
      params.size = size;
    }

    /*   const client = store.getters["customers/getCurrentCustomer"];

    const user = store.getters["auth/getUser"];

    console.log(client); 

    console.log("page" + currentPage);*/

    /*   var config = {
      method: "get",
      url:
        "https://duneceramica.pinchaaqui.net/api/app/allproducts?search=" +
        search +
        "&page=" +
        currentPage,
    };

    return axios(config); */

    return axios.get(`${config.webApiBase}/newlistproducts`, {
      params,
    });
  },
  getProduct(idProduct, comercial = 0) {
    const params = {
      comercial,
    };

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/${idProduct}`}`, {
      params,
    });
  },
  getPrice(params) {
    console.log("PARAMS2: ", params);
    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/price/${params.idProduct}?codcli=${
        params.codcli
      }&inputmeas=${params.input}&quantity=${params.quantity}&almacen=D111`
    );
  },
};
