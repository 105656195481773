import usersApi from "../../api/users-api";

export default {
  namespaced: true,
  state: {
    listUsersRegistered: [],
    totalListUsersRegistered: 0,
    listUsersPending: [],
    totalListUsersPending: 0,
    listUsersActive: [],
    totalListUsersActive: 0,
    listUsersInactive: [],
    totalListUsersInactive: 0,
    listUsersBlocked: [],
    totalListUsersBlocked: 0,
    listUsersClient: [],
    totalListUsersClient: 0,
    listUsersCommercial: [],
    totalListUsersCommercial: 0,
    listUsersCommercialERP: [],
    totalListUsersCommercialERP: 0,
    product: {},
    showModalAdd: false,
    codCli: null,
    language: "es",
  },
  mutations: {
    setListUsersPending(state, payload) {
      state.listUsersPending = payload.data.items;
    },
    setTotalUsersPending(state, payload) {
      state.totalListUsersPending = payload.data.total;
    },
    setListUsersRegistered(state, payload) {
      state.listUsersRegistered = payload.data.items;
    },
    setTotalUsersRegistered(state, payload) {
      state.totalListUsersRegistered = payload.data.total;
    },
    setListUsersActive(state, payload) {
      state.listUsersActive = payload.data.items;
    },
    setTotalUsersActive(state, payload) {
      state.totalListUsersActive = payload.data.total;
    },
    setListUsersInactive(state, payload) {
      state.listUsersInactive = payload.data.items;
    },
    setTotalUsersInactive(state, payload) {
      state.totalListUsersInactive = payload.data.total;
    },
    setListUsersBlocked(state, payload) {
      state.listUsersBlocked = payload.data.items;
    },
    setTotalUsersBlocked(state, payload) {
      state.totalListUsersBlocked = payload.data.total;
    },
    setListUsersClient(state, payload) {
      state.listUsersClient = payload.data.items;
    },
    setTotalUsersClient(state, payload) {
      state.totalListUsersClient = payload.data.total;
    },
    setListUsersCommercial(state, payload) {
      state.listUsersCommercial = payload.data.items;
    },
    setTotalUsersCommercial(state, payload) {
      state.totalListUsersCommercial = payload.data.total;
    },
    setListUsersCommercialERP(state, payload) {
      state.listUsersCommercialERP = payload.data;
    },
    setTotalUsersCommercialERP(state, payload) {
      state.totalListUsersCommercialERP = payload.data.length;
    },
    setCodCli(state, payload) {
      state.codCli = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setShowModalAdd(state, payload) {
      state.showModalAdd = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
    },
  },
  actions: {
    async searchUsersPending(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersPending(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersPending", res);
      context.commit("setTotalUsersPending", res);
    },
    async exportListUsersPending(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.exportUsersPending(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);

      let a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      let blob = new Blob([res.data], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "usuarios-pendientes-" + new Date().getTime() + ".csv";
      a.click();
    },
    async searchUsersRegistered(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersRegistered(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersRegistered", res);
      context.commit("setTotalUsersRegistered", res);
    },
    async exportListUsersRegistered(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.exportUsersRegistered(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);

      let a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      let blob = new Blob([res.data], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "usuarios-registrados-" + new Date().getTime() + ".csv";
      a.click();
    },
    async searchUsersActive(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersActive(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersActive", res);
      context.commit("setTotalUsersActive", res);
    },
    async exportListUsersActive(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.exportUsersActive(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);

      let a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      let blob = new Blob([res.data], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "usuarios-activos-" + new Date().getTime() + ".csv";
      a.click();
    },
    async searchUsersInactive(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersInactive(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersInactive", res);
      context.commit("setTotalUsersInactive", res);
    },
    async searchUsersBlocked(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersBlocked(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersBlocked", res);
      context.commit("setTotalUsersBlocked", res);
    },
    async exportListUsersBlocked(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.exportUsersBlocked(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);

      let a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      let blob = new Blob([res.data], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "usuarios-bloqueados-" + new Date().getTime() + ".csv";
      a.click();
    },
    async searchUsersClient(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersClient(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersClient", res);
      context.commit("setTotalUsersClient", res);
    },
    async searchUsersCommercial(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersCommercial(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersCommercial", res);
      context.commit("setTotalUsersCommercial", res);
    },

    async setLanguage(context, { idioma = "es", idUser }) {
      const res = await usersApi.setLanguage(idioma, idUser);
      console.log(res.data);
      context.commit("setLanguage", res);
    },
    async searchUsersCommercial(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersCommercial(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersCommercial", res);
      context.commit("setTotalUsersCommercial", res);
    },
    async exportListUsersCommercial(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.exportUsersCommercial(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);

      let a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);
      let blob = new Blob([res.data], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "usuarios-comerciales-" + new Date().getTime() + ".csv";
      a.click();
    },
    async searchUsersCommercialERP(
      context,
      { search = "", limit = 10, offset = 0, sort = "", currentPage = "" }
    ) {
      const res = await usersApi.getListUsersCommercialERP(
        search,
        limit,
        offset,
        sort,
        currentPage
      );
      console.log(res.data);
      context.commit("setListUsersCommercialERP", res);
      context.commit("setTotalUsersCommercialERP", res);
    },
    async linkClientMethod(context, { CustomerID = "", UserID = "" }) {
      await usersApi.linkClient(CustomerID, UserID).then(
        (response) => {
          console.log(response.data.msg);
          context.commit("setCodCli", response.data.msg);
        },
        (error) => {
          if (error.response) {
            context.commit("setCodCli", null);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        }
      );
    },
    async unlinkClientMethod(context, { UserID = "" }) {
      await usersApi.unlinkClient(UserID).then(
        (response) => {
          console.log(response.data);
          context.commit("setCodCli", "");
        },
        (error) => {
          if (error.response) {
            context.commit("setCodCli", null);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        }
      );
    },
  },
  getters: {
    getListUsersPending: (state) => state.listUsersPending,
    getTotalUsersPending: (state) => state.totalListUsersPending,
    getListUsersRegistered: (state) => state.listUsersRegistered,
    getTotalUsersRegistered: (state) => state.totalListUsersRegistered,
    getListUsersActive: (state) => state.listUsersActive,
    getTotalUsersActive: (state) => state.totalListUsersActive,
    getListUsersInactive: (state) => state.listUsersInactive,
    getTotalUsersInactive: (state) => state.totalListUsersInactive,
    getListUsersBlocked: (state) => state.listUsersBlocked,
    getTotalUsersBlocked: (state) => state.totalListUsersBlocked,
    getListUsersClient: (state) => state.listUsersClient,
    getTotalUsersClient: (state) => state.totalListUsersClient,
    getListUsersCommercial: (state) => state.listUsersCommercial,
    getTotalUsersCommercial: (state) => state.totalListUsersCommercial,
    getListUsersCommercialERP: (state) => state.listUsersCommercialERP,
    getTotalUsersCommercialERP: (state) => state.totalListUsersCommercialERP,
    getCodCli: (state) => state.codCli,
    getProduct: (state) => state.product,
    getShowModalAdd: (state) => state.showModalAdd,
  },
};
