import productsApi from "../../api/products-api";

export default {
  namespaced: true,
  state: {
    listProducts: [],
    product: {},
    productAdd: {},
    price: { Amount: 0 },
    lotes: [],
    showModalAdd: false,
    totalListProducts: 0,
  },
  mutations: {
    setListProducts(state, payload) {
      state.listProducts = payload.data;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setPrice(state, payload) {
      state.price = payload;
    },
    setLotes(state, payload) {
      state.lotes = payload;
    },
    setProductAdd(state, payload) {
      state.productAdd = payload;
    },
    setTotalListProducts(state, payload) {
      state.totalListProducts = 950;
    },
    setShowModalAdd(state, payload) {
      state.showModalAdd = payload;
    },
  },
  actions: {
    async searchProducts(context, { search = "", limit = 12, offset = 0 }) {
      console.log(search);
      const res = await productsApi.getListProducts(search, limit, offset);

      /*     res.data.data.forEach((element) => {
        if (element.name.includes("MAGNET")) {
          console.log("SI");
        }
      }); */

      /*   var filtered = res.data.data.filter(function(el) {
        return !el.name.includes("MAGNET");
      });

      res.data.data = filtered; */

      context.commit("setListProducts", res);
      context.commit("setTotalListProducts", res);
    },
    async getProduct(context, idProduct) {
      console.log("idProduct:", idProduct);
      const res = await productsApi.getProduct(idProduct, 0);
      context.commit("setProduct", res.data);
    },
    async getPrice(context, params) {
      console.log("idProduct:", params.idProduct);
      console.log("codcli:", params.codcli);
      console.log("quantity:", params.quantity);
      if (params.quantity == 0) {
        var data = {
          Amount: 0,
          Conversion: 0,
          Currency: "EUR",
          Discount: 0,
          Output_pieces: 0,
          Output_qty: 0,
          Output_sqm: 0,
          Output_unit: "m2",
          Price: 0,
          Price_unit: "m2",
        };
        context.commit("setPrice", data);
      } else {
        const res = await productsApi.getPrice(params);
        if (res.data == null) {
          res.data = {
            Amount: 0,
            Currency: "EUR",
            Output_qty: 0.6876,
            Output_unit: "m2",
            Price: 14.5,
            Price_unit: "m2",
          };
        }
        console.log("amount:", res.data);
        context.commit("setPrice", res.data);
      }
    },
    async getProductAdd(context, idProduct) {
      const res = await productsApi.getProduct(idProduct, 1);
      context.commit("setProductAdd", res.data[0]);
    },
  },
  getters: {
    getListProducts: (state) => state.listProducts,
    getProduct: (state) => state.product,
    getProductAdd: (state) => state.productAdd,
    getPrice: (state) => state.price,
    getLotes: (state) => state.lotes,
    getTotalListProducts: (state) => state.totalListProducts,
    getShowModalAdd: (state) => state.showModalAdd,
  },
};
