import customersApi from "../../api/customers-api";
import cartsApi from "../../api/carts-api";

export default {
  namespaced: true,
  state: {
    currentCustomer: "",
    showModalCustomers: true,
    listCustomers: [],
    listCustomersFrecuentes: [],
  },
  mutations: {
    recoverCurrentCustomer(state) {
      const currentCustomer = JSON.parse(
        localStorage.getItem("currentCustomer")
      );
      if (!currentCustomer) {
        state.currentCustomer = null;
        state.showModalCustomers = true;
      } else {
        state.currentCustomer = currentCustomer;
        state.showModalCustomers = false;
      }
    },
    setCurrentCustomer(state, payload) {
      console.log(payload);
      const currentUser = JSON.parse(localStorage.getItem("user"));
      if (currentUser.rol_id == 2) {
        state.currentCustomer = {
          Cliente: currentUser.id_cliente,
          Comercial: "",
          Nombre: currentUser.nombre,
        };
        localStorage.setItem(
          "currentCustomer",
          JSON.stringify(state.currentCustomer)
        );
      } else {
        state.currentCustomer = payload;
        localStorage.setItem(
          "currentCustomer",
          JSON.stringify(state.currentCustomer)
        );
      }

      this.commit("carts/removeLocalCart");
      location.reload();
    },
    setShowModalCustomers(state, payload) {
      state.showModalCustomers = payload;
    },
    setListCustomers(state, payload) {
      state.listCustomers = payload.data;
    },
  },
  actions: {
    async searchCustomers(context, search) {
      const res = await customersApi.getListCustomers(search);
      //  console.log(res.data);
      const res2 = await cartsApi.getCartByVendedor();
      console.log(res2.data);

      if (search != "") {
        search = search.toUpperCase();
        res.data = res.data.filter(function(el) {
          return (
            el.Cliente.toUpperCase().includes(search) ||
            el.Nombre.toUpperCase().includes(search)
          );
        });
      }

      res.data.forEach((element) => {
        if (res2.data.includes(element.Cliente)) {
          element.carrito = true;
        } else {
          element.carrito = false;
        }
      });

      res.data.sort((a, b) =>
        a.carrito === b.carrito ? 0 : a.carrito ? -1 : 1
      );
      console.log(res.data);
      context.commit("setListCustomers", res);
    },
    async searchCustomersAdmin(context, search) {
      const res = await customersApi.getListCustomersAdmin(search);
      context.commit("setListCustomers", res);
    },
    async searchCustomersForCommercial(context, username) {
      console.log("context:: ", context);
      console.log("username:: ", username);
      const res = await customersApi.getListCustomersForCommercial(username);
      console.log("RES:: ", res);
      context.commit("setListCustomers", res);
    },
    async getCustomerLogged(context, search) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      console.log("CURRENT USER:: ", currentUser);
      /*  const res = await customersApi.getCustomerLogged(search); */
      var customer = {
        Comercial: currentUser.id_cliente,
        Cliente: currentUser.id_cliente,
        Nombre: currentUser.nombre,
        carrito: false,
      };
      localStorage.setItem("currentCustomer", JSON.stringify(customer));
      context.commit("setCurrentCustomer", customer);
    },
  },
  getters: {
    getCurrentCustomer: (state) => state.currentCustomer,
    getListCustomers: (state) => state.listCustomers,
    // getCustomerLogged: (state) => state.currentCustomer,
    getListCustomersFrecuentes: (state) => state.listCustomersFrecuentes,
    getShowModalCustomers: (state) => state.showModalCustomers,
  },
};
