import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/pedidos";
const RESOURCE_NAME_SINGULAR = "/pedido";
const RESOURCE_NAME_EDI = "/edi";

export default {
  getListOrdersByClient(
    limit,
    offset,
    sort,
    idClient = 0,
    fecha_ini,
    fecha_fin
  ) {
    var params = {
      fecha_ini: "",
      fecha_fin: "",
    };

    if (fecha_ini) {
      var fech = fecha_ini.replaceAll("/", "");
      var output = fech.substring(0, 4) + "20" + fech.substring(4);

      params.fecha_ini = output;
    }

    if (fecha_fin) {
      var fech = fecha_fin.replaceAll("/", "");
      var output = fech.substring(0, 4) + "20" + fech.substring(4);

      params.fecha_fin = output;
    }

    // const username = "D101224";
    var client = store.getters["customers/getCurrentCustomer"];
    // console.log(client);

    /* client = {};
    client.Cliente = "D101224"; */

    /*  if (fecha_ini) {
      params.fecha_ini = fecha_ini;
    }
    if (fecha_fin) {
      params.fecha_fin = fecha_fin;
    }
    if (sort) {
      params.sort = sort;
    }



    const username = store.getters["auth/getCurrentUsername"];

    const user = store.getters["auth/getUser"];

    console.log("params: ", params);

    if (user.rol_id === 2) {
      //Obtenemos los pedidos de ese usuario como cliente y "admin" como comercial
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comercial/admin/cliente/${
          user.usuario
        }`,
        { params }
      );
    }
    if (idClient === 0) {
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comercial/${username}`,
        { params }
      );
    } else {
 */

    console.log("params: ", params);
    console.log("client: ", client);

    if (client === null) {
      client = JSON.parse(localStorage.getItem("currentCustomer"));
      if (client === null) {
        return { data: [] };
      }
    }

    return axios.get(
      `${config.webApiBase + RESOURCE_NAME}/cliente/${client.Cliente}`,
      { params }
    );
    /*  } */
  },
  getOrder(idOrder) {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME}/lineas/${idOrder}`}`
    );
  },
  createCabecera(order) {

    const user = store.getters["auth/getUser"];
    console.log(user);

    const params = {
      cliente: order.cliente,
      delivery: order.delivery,
      comment: order.comment,
      shippAddr: order.shippAddr,
      email: user.email,
      ref: order.ref,
    };
    return axios.post(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/cabecera`}`,
      params
    );
  },
  createLinea(linea) {
    console.log("Linea: ", linea);

    var params = {
      pedido: linea.IDPEDIDO,
      codart: linea.CODART,
      cantidad: parseFloat(linea.CANTIDAD),
      unidades: linea.UNITMEAS,
    };

    console.log(params);
    return axios.post(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/linea`}`,
      params
    );
  },
  confirmOrder(pedido) {
    console.log("pedido: ", pedido);

    var params = {
      pedido: pedido.idPedido,
      carrito: pedido.idCarrito,
    };

    console.log(params);
    return axios.post(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/confirmar`}`,
      params
    );
  },
};
