import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/sizes";

export default {
  getSizes(family) {
    console.log(family);
    if (family != null) {
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}?familia=` + family,
        {}
      );
    }
    return axios.get(`${config.webApiBase + RESOURCE_NAME}`, {});
  },
};
