import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/informes";
const RESOURCE_NAME_SINGULAR = "/informe";

export default {
  getListReports(limit, offset, sort) {
    const params = {
      limit,
      offset,
    };
    if (sort) {
      params.sort = sort;
    }
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/all`, { params });
  },

  getReport(idReport) {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/${idReport}`}`
    );
  },

  createReport(comment, fecha) {
    const carrito = store.getters["carts/getCart"];

    const newLineas = carrito.lineas.map((rec) => {
      const cantped = (rec.cantidad * rec.metrosCaja).toFixed(6);

      const totalCajas = rec.cantidad;
      return {
        cajasTotales: totalCajas,
        picking: rec.pickingLine,
        refean: rec.idArticulo,
        cantped: cantped,
        umedida: rec.unidades,
        cajasPalet: rec.cajasPalet,
        metrosCaja: rec.metrosCaja,
      };
    });

    var params = {
      comprador: carrito.lineas[0].idCliente,
      vendedor: carrito.lineas[0].idComercial,
      lines: newLineas,
      comment: {
        tema: "AAI",
        texto1: comment,
      },
      fechav: fecha,
    };

    return axios.post(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}`}`,
      params
    );
  },
  createFlujo() {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_EDI}/flujo/todo`}`
    );
  },
};
